import { combineReducers, configureStore } from '@reduxjs/toolkit';
import User from './User';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import multipleInspectionReducer from './multipleInspectionSlice';
import storeGuidReducer from './StoreGuid';
import { Logout } from './User';

const persistConfig = {
  key: 'root',
  storage,
};

let timer;

const rootReducer = combineReducers({
  User: User,
  multipleInspection: multipleInspectionReducer,
  GuidInfo: storeGuidReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const autoLogoutMiddleware = (store) => (next) => (action) => {
  clearTimeout(timer);

  timer = setTimeout(() => {
    store.dispatch(Logout());
  }, 5000 * 1000); // 5 seconds
  next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(autoLogoutMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
