import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Grid,
  CircularProgress,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Modal,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  InputLabel,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  Chip,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import QrReader from 'react-qr-scanner';
import { useDispatch, useSelector } from 'react-redux';
import { setGuid } from '../../state/StoreGuid';
import { InspectorQR, ReasonsAPI } from '../../http/server-apis';
import { BiQrScan, BiSolidCheckCircle, BiXCircle } from 'react-icons/bi';
import CrossImage from '../../assets/images/cross.png';
import { useNavigate } from 'react-router-dom';
import { Logout } from '../../state/User';

const InspectionHome = () => {
  const { InspectorID, DeviceInfoId, Login } = useSelector(
    (state) => state.User
  );
  const { guid } = useSelector((state) => state.GuidInfo);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [GuidOpen, setGuidOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [scanQr, setScanQr] = useState(true);
  const [qrData, setQrData] = useState([]);
  const [checkAgainReason, setCheckAgainReason] = useState('');
  const [showData, setShowData] = useState(false);
  const [isClicked, setIsClicked] = useState({
    checkAgain: false,
    defected: false,
    customReason: false,
  });
  const [selectedDefectReason, setSelectedDefectReason] = useState('');
  const [selectedDefectReasonID, setSelectedDefectReasonID] = useState('');
  const [loading, setLoading] = useState(false);
  const [qrError, setQRError] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [reasonsList, setReasonsList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [guidData, setGuidData] = useState({});

  const handleRadioChange = (event, id, reasonName) => {
    setSelectedDefectReason(reasonName);
    setSelectedDefectReasonID(id);
    setCustomReason('');
  };

  const resetSelectedValues = () => {
    setSelectedDefectReasonID(null);
    setSelectedDefectReason('');
    setCustomReason('');
  };

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value);
  };

  useEffect(() => {
    if (!navigator.mediaDevices) {
      console.log('Unable to capture WebCam.');
    } else {
      console.log('capture WebCam.');
    }
  }, [scanQr]);

  // useEffect(() => {
  //   if (guidData.guid) {
  //     dispatch(setGuid(guidData));
  //   }
  // }, [dispatch, guidData]);

  useEffect(() => {
    if (!Login) {
      Navigate('/inspection-login');
    }
  }, [Login]);

  const handleScan = (data) => {
    if (data) {
      setData(data);
      setScanQr(false);

      if (data?.text) {
        setIsDisable(false);

        // const foundGuid = guid.find((item) => item.guid === data?.text);
        // if (foundGuid) {
        //   setGuidOpen(true);
        // } else {
        //   setGuidOpen(false);
        // }
      }
    }
  };

  const handleCloseGuid = (override) => {
    setGuidOpen(false);

    if (override) {
      setShowData(true);
      dispatch(setGuid(guidData));
    } else {
      setScanQr(true);
      setShowData(false);
      // dispatch(setAutoClear());
    }
  };

  function handleError(err) {
    console.error(err);
    setIsDisable(true);
  }
  async function reasonDropdown() {
    try {
      const res = await ReasonsAPI('get');
      setReasonsList(res?.data);
    } catch (err) {}
  }
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const headers = {
        Apikey: 123456,
      };

      try {
        const response = await InspectorQR('get', {
          postfix: `?GUID=${data?.text}&InspectorID=${InspectorID}`,
          headers: headers,
        });
        if (response?.status === 200 && response?.data?.status === 'failed') {
          setIsLoading(false);
          setShowData(false);
          setQRError('Qr  Not Found!');
          return;
        }
        if (!response?.data?.Allowed) {
          toast.error(
            'Sorry, you are not allowed to scan. Please contact admin and then try to login again',
            { duration: 10000 }
          );
          dispatch(Logout());
          return;
        }
        if (response?.data?.Flag) {
          setIsLoading(false);
          setGuidOpen(true);
          // return;
        }
        if (response?.status === 200) {
          setQrData(response?.data);

          setIsLoading(false);
          setShowData(true);
          setQRError('');
          const newGuidData = {
            guid: data?.text,
            ...response?.data,
          };
          setGuidData(newGuidData);
        } else {
          setIsLoading(false);
          setShowData(false);
          setQRError('Qr Not Found!');
        }
      } catch (error) {
        console.log('error', error);

        toast.error(error);
        setIsLoading(false);
        setShowData(false);
        setQRError('Error fetching data from the server.');
      }
    };

    if (data?.text) {
      getData();
      reasonDropdown();
    }
  }, [InspectorID, data, dispatch]);

  async function onUpdateStatusHandler(status) {
    const params_data = {
      ID: Number(DeviceInfoId),
      Status: status,
      GUID: data?.text,
      InspectorID: InspectorID,
    };
    const response = await InspectorQR('post', {
      data: params_data,
    });

    const data2 = response?.data;
    if (data2.status == 'success') {
      toast.success(data2.message);
      setIsDisable(true);
      setData(null);
      setQrData({});
      setIsDisable(true);
      setShowData(false);
      setCheckAgainReason('');
      setScanQr(true);
      setIsClicked({
        defected: false,
        checkAgain: false,
      });
      dispatch(setGuid(guidData));
    } else {
      toast.error('Something went wrong!');
      setIsDisable(false);
    }
  }
  async function onUpdateStatusHandler2(status) {
    const params_data = {
      ID: Number(DeviceInfoId),
      Status: status,
      GUID: data?.text,
      InspectorID: InspectorID,
      ReasonID: selectedDefectReasonID,
      OtherReason: customReason,
      ReasonName: selectedDefectReason,
    };

    const response = await InspectorQR('post', {
      data: params_data,
    });

    const data2 = response?.data;

    if (data2.status === 'success') {
      toast.success(data2.message);
      setIsDisable(true);
      setData(null);
      setQrData({});
      setScanQr(true);
      setShowData(false);
      setCheckAgainReason('');
      setSelectedDefectReason('');
      setIsClicked({
        defected: false,
        checkAgain: false,
        customReason: false,
      });
      setSelectedDefectReason('');
      setCustomReason('');
      dispatch(setGuid(guidData));
    } else {
      toast.error('Something went wrong!');
    }
  }

  async function onCheckStatusHandler(status) {
    setIsDisable(true);
    setLoading(true);
    try {
      if (selectedDefectReason === 'Others' && customReason.length === 0) {
        setIsDisable(false);
        return;
      }

      if (status === 0 || status === 2) {
        if (
          (status === 0 && selectedDefectReason?.length > 0) ||
          (status === 2 && checkAgainReason?.length > 0)
        ) {
          await onUpdateStatusHandler2(status);
        }
      } else {
        await onUpdateStatusHandler(status);
      }

      handleClose();
      resetSelectedValues();
    } catch (error) {
      toast.error('Something went wrong!');
      setIsDisable(false);
    } finally {
      setLoading(false);
    }
  }

  function onScanButtonHandler() {
    setScanQr(!scanQr);
    setData(null);
    setQrData({});
    setIsLoading(false);
    setShowData(false);
    setCheckAgainReason('');
    setIsClicked({
      defected: false,
      checkAgain: false,
    });

    if (!scanQr) {
      setIsDisable(true);
    }
  }

  useEffect(() => {
    if (selectedDefectReason?.length > 0 && isClicked.defected) {
      setIsClicked({
        defected: false,
        checkAgain: false,
        customReason: false,
      });
      return;
    }
    if (checkAgainReason?.length > 0 && isClicked.checkAgain) {
      setIsClicked({
        defected: false,
        checkAgain: false,
        customReason: false,
      });
      return;
    }
    if (customReason?.length > 0 && isClicked.customReason) {
      setIsClicked({
        defected: false,
        checkAgain: false,
        customReason: false,
      });
      return;
    }
  }, [
    checkAgainReason,
    selectedDefectReason,
    isClicked.checkAgain,
    isClicked.defected,
    isClicked.customReason,
    customReason,
  ]);
  const handleScanAgain = () => {
    setScanQr(true);
    setQRError('');
  };
  const refresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      {qrError ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100vh',
              textAlign: 'center',
            }}
          >
            <img
              src={CrossImage}
              alt='Udyogi-logo'
              style={{
                width: '20%',
                margin: '0px auto',
                display: 'block',
              }}
            />
            <Typography variant='subtitle1' color='error' marginTop='10px'>
              QR code not found!
            </Typography>

            <Button
              onClick={handleScanAgain}
              variant='contained'
              sx={{
                padding: '8px 23px',
                backgroundColor: `${qrError ? '#333' : '#fe0100'} !important`,
                fontSize: '1rem',
                fontWeight: '600',
                textTransform: 'capitalize',
                color: '#fff',
                marginTop: '15px',
                fontFamily: 'Lato, sans-serif',
              }}
            >
              <BiQrScan
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
              />{' '}
              Scan Again
            </Button>
          </Box>
        </>
      ) : (
        <Grid container>
          <Grid item xs={12} textAlign='center'>
            {qrError && (
              <Box
                sx={{
                  marginTop: '10px',
                  padding: '15px',
                  backgroundColor: '#ffcccc',
                  borderRadius: '4px',
                  textAlign: 'center',
                }}
              >
                <Typography variant='subtitle1' color='error'>
                  {qrError}
                </Typography>
              </Box>
            )}
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              padding='10px 10px'
              backgroundColor='#f5f5f5'
              position='fixed'
              top='7%'
              left='0%'
              width='100%'
              className='bread-title'
            >
              <Typography
                variant='h6'
                sx={{
                  fontFamily: 'Lato, sans-serif',
                  fontWeight: 600,
                }}
              >
                Inspector Dashboard
              </Typography>
            </Box>
            {scanQr && guid?.length > 0 ? (
              <Box
                sx={{
                  padding: '10px 10px',
                  marginTop: '50px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant='h6'
                  fontSize='1rem'
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  Last Scanned:
                </Typography>
                {guid[0] ? (
                  <Chip
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '5px',
                          height: '30px',
                        }}
                      >
                        <span style={{ color: '#000000', fontSize: '15px' }}>
                          Model:
                        </span>
                        <span style={{ color: '#4B0082', fontSize: '14px' }}>
                          {guid[0].ShortCode}
                        </span>
                        {/* <span style={{ color: '#000000', fontSize: '15px' }}>
                          Batch:
                        </span>
                        <span style={{ color: '#4B0082', fontSize: '14px' }}>
                          {guid[1].BatchNo}
                        </span> */}
                        <span style={{ color: '#000000', fontSize: '15px' }}>
                          Sl:{' '}
                        </span>
                        <span style={{ color: '#4B0082', fontSize: '14px' }}>
                          {guid[0].SerialNO}
                        </span>
                      </Box>
                    }
                    color='primary'
                    variant='outlined'
                    sx={{
                      marginTop: '10px',
                      fontSize: '1.2em',
                    }}
                  />
                ) : (
                  <Typography
                    variant='body1'
                    sx={{
                      fontFamily: 'Lato, sans-serif',
                      marginTop: '10px',
                    }}
                  >
                    No Last Scan
                  </Typography>
                )}
              </Box>
            ) : null}

            <Box
              sx={{
                marginTop: '50px',
              }}
            >
              {scanQr ? (
                <>
                  <QrReader
                    delay='500'
                    resolution={800}
                    key={refreshKey}
                    style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                    constraints={{
                      video: { facingMode: 'environment' },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '0.9rem',
                      color: '#8d8787',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <BiQrScan
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '5px',
                      }}
                    />{' '}
                    Scan QR code
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      onClick={refresh}
                      variant='contained'
                      sx={{
                        padding: '6px 23px',
                        backgroundColor: `${
                          refresh ? '#333' : '#fe0100'
                        } !important`,
                        fontSize: '1rem',
                        fontWeight: '600',
                        textTransform: 'capitalize',
                        color: '#fff',
                        textAlign: 'center',
                        marginTop: '15px',
                        fontFamily: 'Lato, sans-serif',
                      }}
                    >
                      Refresh
                      {/* <IoMdRefresh
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '5px',
                }}
              /> */}
                    </Button>
                  </div>
                </>
              ) : null}
            </Box>

            {isLoading ? (
              <CircularProgress />
            ) : (
              <Dialog
                open={GuidOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                disableBackdropClick
                disableEscapeKeyDown
                style={{
                  zIndex: 9999,
                }}
                sx={{
                  '& .MuiBackdrop-root': {
                    backdropFilter: 'blur(5px)',
                  },
                }}
              >
                <DialogTitle id='alert-dialog-title'>
                  {'Override Scan'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    This GUID is already scanned. Do you want to override it?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleCloseGuid(false)}
                    color='primary'
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => handleCloseGuid(true)}
                    color='primary'
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {/* {isLoading ? <CircularProgress /> : null} */}
            {!isLoading && showData ? (
              <Box
                sx={{
                  padding: '10px ',
                }}
              >
                <Box
                  sx={{
                    boxShadow: '0  0 14px #ccc',
                    padding: '10px 10px',
                  }}
                >
                  <List>
                    <ListItem
                      sx={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      <ListItemText>
                        <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                          QR ID
                        </Typography>
                        <Typography variant='p' sx={valueStyle}>
                          : {data?.text}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      sx={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      <ListItemText>
                        <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                          Product Name
                        </Typography>
                        <Typography variant='p' sx={valueStyle}>
                          : {qrData?.ProductName}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      sx={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      <ListItemText>
                        <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                          Item Code
                        </Typography>
                        <Typography variant='p' sx={valueStyle}>
                          : {qrData?.ItemCode}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      sx={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      <ListItemText>
                        <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                          Model
                        </Typography>
                        <Typography variant='p' sx={valueStyle}>
                          : {qrData?.ShortCode}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      sx={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      <ListItemText>
                        <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                          Batch No
                        </Typography>
                        <Typography variant='p' sx={valueStyle}>
                          : {qrData?.BatchNo}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      sx={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      <ListItemText>
                        <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                          Serial No
                        </Typography>
                        <Typography variant='p' sx={valueStyle}>
                          : {qrData?.SerialNO}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        disabled={isDisable}
                        onClick={() => onCheckStatusHandler(1)}
                        sx={{
                          padding: '8px 20px',
                          fontSize: '1rem',
                          fontWeight: '600',
                          textTransform: 'capitalize',
                          color: '#fff !important',
                          backgroundColor: '#28a400',
                          marginTop: '10px',
                          marginBottom: '10px',
                          marginRight: '10px',
                          fontFamily: 'Lato, sans-serif',

                          '&:hover': {
                            backgroundColor: '#ccc;',
                            color: '#000 !important',
                          },
                        }}
                      >
                        <BiSolidCheckCircle
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        {isDisable ? (
                          <CircularProgress size={20} />
                        ) : (
                          'Marked OK'
                        )}
                      </Button>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display='flex'
                      alignItems='center'
                      justifyContent='end'
                      flexDirection='column'
                    >
                      {!scanQr ? (
                        <Button
                          onClick={() => onScanButtonHandler()}
                          variant='contained'
                          sx={{
                            padding: '8px 23px',
                            backgroundColor: `${
                              !scanQr ? '#333' : '#fe0100'
                            } !important`,
                            fontSize: '1rem',
                            fontWeight: '600',
                            textTransform: 'capitalize',
                            color: '#fff',
                            marginRight: '10px',
                            fontFamily: 'Lato, sans-serif',
                          }}
                        >
                          <BiQrScan
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                            }}
                          />{' '}
                          Scan Again
                        </Button>
                      ) : null}

                      <Box mb={2} mt={1} width='100%'>
                        <div>
                          <Button
                            sx={{
                              padding: '8px 27px',
                              fontSize: '1rem',
                              fontWeight: '600',
                              textTransform: 'capitalize',
                              color: '#fff',
                              backgroundColor: '#ff0000',
                              marginTop: '10px',
                              marginBottom: '10px',
                              marginRight: '10px',
                              fontFamily: 'Lato, sans-serif',
                              '&:hover': {
                                backgroundColor: '#ccc;',
                                color: '#000 !important',
                              },
                            }}
                            onClick={handleOpen}
                          >
                            <BiXCircle
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                              }}
                            />{' '}
                            Defective
                          </Button>
                          <Grid container>
                            <Grid item xs={12}>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby='modal-modal-title'
                                aria-describedby='modal-modal-description'
                              >
                                <Box sx={style}>
                                  <Typography
                                    id='modal-modal-title'
                                    variant='h6'
                                    backgroundColor='#f5f5f5'
                                    paddingY={2}
                                    paddingX={2}
                                    sx={{
                                      borderTopLeftRadius: '10px',
                                      borderTopRightRadius: '10px',
                                    }}
                                  >
                                    Reasons of Defective
                                  </Typography>
                                  <Box
                                    sx={{
                                      padding: '10px 30px 0px 30px ',
                                    }}
                                  >
                                    {reasonsList.map((data) => (
                                      <div key={data.ReasonID}>
                                        <FormControl>
                                          <RadioGroup
                                            aria-labelledby='demo-radio-buttons-group-label'
                                            value={selectedDefectReasonID}
                                            // checked={}
                                            onChange={(e) =>
                                              handleRadioChange(
                                                e,
                                                data.ReasonID,
                                                data.Reason
                                              )
                                            }
                                          >
                                            <FormControlLabel
                                              value={data.ReasonID}
                                              control={<Radio size='small' />}
                                              label={data.Reason}
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    ))}
                                  </Box>

                                  <Box
                                    sx={{
                                      padding: '10px 30px',
                                    }}
                                  >
                                    {selectedDefectReasonID == 6 && (
                                      <>
                                        <InputLabel
                                          sx={{
                                            fontSize: '1rem',
                                            paddingLeft: '10px',
                                            fontWeight: 600,
                                            fontFamily: 'Lato, sans-serif',
                                          }}
                                        >
                                          Other Reason
                                        </InputLabel>
                                        <TextField
                                          // label='Other Reason'
                                          inputProps={{ maxLength: 100 }}
                                          variant='outlined'
                                          multiline
                                          sx={{
                                            width: '100%',
                                          }}
                                          fullWidth
                                          value={customReason}
                                          onChange={handleCustomReasonChange}
                                          error={isClicked.customReason}
                                          helperText={
                                            isClicked.customReason
                                              ? 'mandatory'
                                              : null
                                          }
                                        />
                                      </>
                                    )}
                                  </Box>
                                  <Box
                                    display='flex'
                                    justifyContent='end'
                                    gap={2}
                                    alignItems='center'
                                  >
                                    <Button
                                      variant='contained'
                                      sx={{
                                        padding: '5px 12px',
                                        backgroundColor: '#6d6c6c',
                                        fontSize: '0.9rem',
                                        fontWeight: '600',
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        fontFamily: 'Lato, sans-serif',
                                        marginBottom: '10px',

                                        '&:hover': {
                                          backgroundColor: '#6d6c6c',
                                        },
                                      }}
                                      disabled={loading}
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant='contained'
                                      color='secondary'
                                      sx={{
                                        padding: '5px 12px',
                                        backgroundColor: qrError
                                          ? '#ccc'
                                          : '#333',
                                        fontSize: '0.9rem',
                                        fontWeight: '600',
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        fontFamily: 'Lato, sans-serif',
                                        float: 'right',
                                        '&:hover': {
                                          backgroundColor: qrError
                                            ? '#ccc'
                                            : '#333',
                                        },
                                      }}
                                      disabled={
                                        (selectedDefectReason == 'Others' &&
                                          customReason?.length == 0) ||
                                        !selectedDefectReason > 0
                                      }
                                      onClick={() => {
                                        onCheckStatusHandler(0);
                                        if (
                                          selectedDefectReason?.length === 0
                                        ) {
                                          setIsClicked({
                                            defected: true,
                                            checkAgain: false,
                                            customReason: false,
                                          });
                                        } else if (
                                          selectedDefectReason?.length > 0 &&
                                          customReason.length === 0
                                        ) {
                                          setIsClicked({
                                            defected: false,
                                            checkAgain: false,
                                            customReason: true,
                                          });
                                        } else {
                                          setIsClicked({
                                            defected: false,
                                            checkAgain: false,
                                            customReason: false,
                                          });
                                        }
                                      }}
                                    >
                                      {loading ? (
                                        <CircularProgress size={24} />
                                      ) : (
                                        'Defective'
                                      )}
                                    </Button>
                                  </Box>
                                </Box>
                              </Modal>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InspectionHome;

const previewStyle = {
  height: 400,
  width: 350,
};
const labelStyle = {
  display: 'block',
  width: '40%',
  float: 'left',
  color: '#8d8787',
  fontSize: '1rem',
  fontFamily: 'Lato, sans-serif',
  fontWeight: 400,
};
const valueStyle = {
  display: 'block',
  width: '60%',
  float: 'left',
  paddingLeft: '10px',
  fontSize: '1rem',
  fontFamily: 'Lato, sans-serif',
  fontWeight: 600,
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  border: '2px solid #FF9800',
  boxShadow: '24',
  borderRadius: '10px',
};
