import QrReader from 'react-qr-scanner';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProductDetails from '../customer/CustomerProductDetails';
import { ScannerApi } from '../../http/server-apis';
import { useSelector } from 'react-redux';
import { BiQrScan } from 'react-icons/bi';
import CrossImage from '../../assets/images/cross.png';
const defaultStyle = {
  height: 400,
  width: 350,
};

const Qr = ({ previewStyle }) => {
  const [data, setData] = useState();
  const { DeviceInfoId } = useSelector((state) => state.User);
  const [isLoading, setIsLoading] = useState(false);
  const [scanQr, setScanQr] = useState(true);
  const [qrData, setQrData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!navigator.mediaDevices) {
      console.log('Unable to capture WebCam.');
    } else {
      console.log('Capture WebCam.');
    }
  }, [scanQr]);

  function handleScan(data) {
    if (data) {
      setData(data);
      setScanQr(false);
      setError(null);
    }
  }

  function handleError(err) {
    console.error(err);
    setError('There was an error fetching data from the server.');
  }

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const headers = {
        Apikey: 123456,
      };
      const params_data = {
        ID: Number(DeviceInfoId),
        GUID: data?.text,
      };

      try {
        const response = await ScannerApi('post', {
          data: params_data,
          headers: headers,
        });

        if (response?.data?.status === 'success') {
          setQrData(response?.data);
        } else {
          setError('QR code not found! ');
        }
      } catch (error) {
        setError('Error fetching data from the server.');
      } finally {
        setIsLoading(false);
      }
    };

    if (data?.text) {
      getData();
    }
  }, [data, DeviceInfoId]);

  const isObjectEmpty = (objectName) => {
    return (
      Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  };

  function handleScanQRBtn() {
    setScanQr(!scanQr);
    setData(null);
    setQrData({});
    setError(null);
  }

  function handleScanNowBtn() {
    setScanQr(true);
    setData(null);
    setQrData({});
    setError(null);
  }

  return (
    <Grid container>
      <Grid item xs={12} px='20px'>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          padding='10px 10px'
          backgroundColor='#f5f5f5'
          position='fixed'
          top='7%'
          left='0%'
          width='100%'
          zIndex={999}
          className='bread-title'
        >
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Lato, sans-serif',
              fontWeight: 600,
            }}
          >
            {qrData?.ProductName ? 'Product Details' : ''}
          </Typography>
        </Box>

        {scanQr ? (
          <>
            <QrReader
              delay='500'
              resolution={600}
              style={previewStyle || defaultStyle}
              onError={handleError}
              onScan={handleScan}
              constraints={{
                video: { facingMode: 'environment' },
              }}
            />

            <Typography
              sx={{
                fontSize: '0.9rem',
                color: '#8d8787',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BiQrScan
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
              />{' '}
              Scan QR code
            </Typography>
          </>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '25px',
        }}
      >
        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            <CircularProgress />
          </div>
        )}

        {error && (
          <>
            <img
              src={CrossImage}
              alt='Udyogi-logo'
              style={{
                width: '20%',
                margin: '0px auto',
                display: 'block',
              }}
            />
            <Typography
              sx={{
                fontSize: '1rem',
                color: '#fe0000',
                textAlign: 'center',
                marginTop: '10px',
              }}
            >
              {error}
            </Typography>
            <Button
              onClick={handleScanQRBtn}
              variant='contained'
              sx={{
                padding: '8px 23px',
                backgroundColor: `${error ? '#333' : '#fe0100'} !important`,
                fontSize: '1rem',
                fontWeight: '600',
                textTransform: 'capitalize',
                color: '#fff',
                marginRight: '10px',
                textAlign: 'center',
                marginTop: '15px',
                fontFamily: 'Lato, sans-serif',
              }}
            >
              <BiQrScan
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '5px',
                }}
              />{' '}
              Scan Again
            </Button>
          </>
        )}

        {!isLoading && !isObjectEmpty(qrData) && (
          <ProductDetails qrData={qrData} onScan={handleScanNowBtn} />
        )}
      </Grid>
    </Grid>
  );
};

export default Qr;
