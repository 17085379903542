import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home/Home';
import MainLayout from './layout/MainLayout';
import InspectionHome from './pages/inspection/InspectionHome';
import InspectionLogin from './pages/login/InspectionLogin';
import BurgerMenu from './components/BurgerMenu';
import './App.css';
import { useSelector } from 'react-redux';
import IFramePreview from './pages/customer/IFramePreview';
import InspectionList from './pages/InspectionList/InspectionList';
import ContactUsPage from './pages/ContactUs/ContactUs';
import MultipleInspection from './pages/multipleInspection/MultipleInspection';
import TotalScanList from './pages/InspectionList/TotalScanList';
function App() {
  const { Login } = useSelector((state) => state.User);

  return (
    <MainLayout>
      <BurgerMenu />
      <Routes>
        <Route path='/' exact element={Login ? <InspectionHome /> : <Home />} />{' '}
        <Route path='/inspection-home' exact element={<InspectionHome />} />{' '}
        <Route path='/inspection-login' exact element={<InspectionLogin />} />
        <Route path='/Inspection-list' exact element={<InspectionList />} />
        <Route path='/contact-us' exact element={<ContactUsPage />} />
        <Route path='/pdf-preview' exact element={<IFramePreview />} />
        <Route path='/scan-qr' exact element={<Home />} />
        <Route path='/multiple-scan' exact element={<MultipleInspection />} />
        <Route path='/total-scan-list' exact element={<TotalScanList />} />
        <Route path='/total-scan-list/:id' exact element={<TotalScanList />} />
      </Routes>
    </MainLayout>
  );
}

export default App;
