import axios from "axios";

export const appUrl = "https://qrapi.ekant.in/api/";

const baseUrl = appUrl;

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Apikey: 123456,
    // "Content-Type": "application/json",
    // Accept: "application/json",
  },
});

export const baseFunc = (endURL) => {
  return (method, options) => {
    const params = options?.params ? "/" + options.params : "";
    let url = `/${endURL}${params}`;
    if (options?.postfix) {
      url += options.postfix;
    }

    if (method.toLowerCase() === "get") {
      return api.get(url);
    } else if (method.toLowerCase() === "post") {
      return api.post(url, options?.data);
    } else if (method.toLowerCase() === "put") {
      return api.put(url, options?.data);
    } else if (method.toLowerCase() === "delete") {
      if (options?.data)
        return api.delete(url, {
          data: options?.data,
        });
      else
        return api.delete(url, {
          data: JSON.stringify({ deleted: 1 }),
        });
    }
  };
};
