import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  InspectorID: '',
  Username: '',
  Login: false,
  DeviceInfoId: '',
  Allowed: 0,
  TimeFrom: '',
  TimeTo: '',
};

const User = createSlice({
  name: 'user',
  initialState,
  reducers: {
    Login(state, action) {
      state.InspectorID = action.payload.InspectorID;
      state.Username = action.payload.Username;
      state.Login = true;
      state.Allowed = action.payload.Allowed;
      state.TimeFrom = action.payload.TimeFrom;
      state.TimeTo = action.payload.TimeTo;
    },
    UpdateDeviceInfoID(state, action) {
      state.DeviceInfoId = action.payload;
    },
    Logout(state) {
      state.InspectorID = '';
      state.Username = '';
      state.Login = false;
      state.Allowed = 0;
      state.TimeFrom = '';
      state.TimeTo = '';
    },
  },
});

export const { Login, Logout, UpdateDeviceInfoID } = User.actions;

export default User.reducer;
