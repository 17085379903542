import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';

const ProductDetails = ({ qrData, scanQr, onScan }) => {
  const labelStyle = {
    display: 'block',
    width: '40%',
    float: 'left',
    color: '#8d8787',
    fontSize: '1rem',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 400,
  };
  const valueStyle = {
    display: 'block',
    width: '60%',
    float: 'left',
    paddingLeft: '10px',
    fontSize: '1rem',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 600,
  };
  const handleToast = () => {
    toast.error('Document not available');
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} px={2} mt={7}>
          <img
            src={qrData.ProductImage}
            alt={qrData.ProductName}
            style={{
              width: '100%',
              height: '40vh',
              objectFit: 'contain',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              padding: '10px',
              border: '1px solid rgb(230, 230, 230)',
            }}
          />
          <Box mt={1}>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <List>
              <ListItem
                sx={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                <ListItemText
                  sx={{
                    padding: '0px 10px',
                  }}
                >
                  <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                    Product Name
                  </Typography>
                  <Typography variant='p' sx={valueStyle}>
                    : {qrData.ProductName}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem
                sx={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                <ListItemText
                  sx={{
                    padding: '0px 10px',
                  }}
                >
                  <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                    Item Code{' '}
                  </Typography>
                  <Typography variant='p' sx={valueStyle}>
                    : {qrData.ItemCode}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                sx={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                <ListItemText
                  sx={{
                    padding: '0px 10px',
                  }}
                >
                  <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                    Model
                  </Typography>
                  <Typography variant='p' sx={valueStyle}>
                    : {qrData.ShortCode}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem
                sx={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                <ListItemText
                  sx={{
                    padding: '0px 10px',
                  }}
                >
                  <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                    Batch No
                  </Typography>
                  <Typography variant='p' sx={valueStyle}>
                    : {qrData.BatchNo}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                sx={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                <ListItemText
                  sx={{
                    padding: '0px 10px',
                  }}
                >
                  <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                    Serial No
                  </Typography>
                  <Typography variant='p' sx={valueStyle}>
                    : {qrData.SerialNO}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem
                sx={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                <ListItemText
                  sx={{
                    padding: '0px 10px',
                  }}
                >
                  <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                    Description
                  </Typography>
                  <Typography
                    variant='p'
                    sx={[valueStyle, { wordBreak: 'break-all' }]}
                  >
                    : {qrData.Description ? qrData.Description : '-'}
                  </Typography>
                </ListItemText>
              </ListItem>

              {qrData.Description ? (
                <ListItem
                  sx={{
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                >
                  <ListItemText
                    sx={{
                      padding: '0px 10px',
                    }}
                  >
                    <Typography variant='p' color='#9e9e9e' sx={labelStyle}>
                      Description
                    </Typography>
                    <Typography variant='p' sx={valueStyle}>
                      :{qrData.Description}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ) : null}
            </List>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap={2}
            marginBottom='10px'
          >
            {!scanQr ? (
              <Button
                onClick={() => onScan()}
                variant='contained'
                sx={{
                  padding: '5px 12px',
                  backgroundColor: `${!scanQr ? '#333' : '#fe0100'} !important`,
                  fontSize: '0.9rem',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  color: '#fff',
                  fontFamily: 'Lato, sans-serif',
                }}
              >
                Scan
              </Button>
            ) : null}

            {qrData?.Certificate ? (
              <a href={qrData?.Certificate} target='_blank' rel='noreferrer'>
                <Button
                  variant='contained'
                  sx={{
                    padding: '5px 12px',
                    backgroundColor: '#6d6c6c',
                    fontSize: '0.9rem',
                    fontWeight: '600',
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontFamily: 'Lato, sans-serif',
                    '&:hover': {
                      backgroundColor: '#6d6c6c',
                    },
                  }}
                >
                  Certificate
                </Button>
              </a>
            ) : (
              <Button
                variant='contained'
                sx={{
                  padding: '5px 12px',
                  backgroundColor: '#ccc',
                  fontSize: '0.9rem',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  color: '#fff',
                  fontFamily: 'Lato, sans-serif',
                  '&:hover': {
                    backgroundColor: '#ccc',
                  },
                }}
                onClick={handleToast}
              >
                Certificate
              </Button>
            )}

            {qrData?.DataSheet ? (
              <a href={qrData?.DataSheet} target='_blank' rel='noreferrer'>
                <Button
                  variant='contained'
                  sx={{
                    padding: '5px 12px',
                    backgroundColor: '#6d6c6c',
                    fontSize: '0.9rem',
                    fontWeight: '600',
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontFamily: 'Lato, sans-serif',
                    '&:hover': {
                      backgroundColor: '#6d6c6c',
                    },
                  }}
                >
                  DataSheet
                </Button>
              </a>
            ) : (
              <Button
                variant='contained'
                sx={{
                  padding: '5px 12px',
                  backgroundColor: '#ccc',
                  fontSize: '0.9rem',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  color: '#fff',
                  fontFamily: 'Lato, sans-serif',
                  '&:hover': {
                    backgroundColor: '#ccc',
                  },
                }}
                onClick={handleToast}
              >
                DataSheet
              </Button>
            )}
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            {qrData?.DeclarationConformity ? (
              <a
                href={qrData?.DeclarationConformity}
                target='_blank'
                rel='noreferrer'
              >
                <Button
                  variant='contained'
                  sx={{
                    padding: '5px 12px',
                    backgroundColor: '#6d6c6c',
                    fontSize: '0.9rem',
                    fontWeight: '600',
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontFamily: 'Lato, sans-serif',
                    '&:hover': {
                      backgroundColor: '#6d6c6c',
                    },
                  }}
                >
                  Declaration Conformity
                </Button>
              </a>
            ) : (
              <Button
                variant='contained'
                sx={{
                  padding: '5px 12px',
                  backgroundColor: '#ccc',
                  fontSize: '0.9rem',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  color: '#fff',
                  fontFamily: 'Lato, sans-serif',
                  '&:hover': {
                    backgroundColor: '#ccc',
                  },
                }}
                onClick={handleToast}
              >
                Declaration Conformity
              </Button>
            )}
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            {qrData?.UserManual ? (
              <a href={qrData?.UserManual} target='_blank' rel='noreferrer'>
                <Button
                  variant='contained'
                  sx={{
                    padding: '5px 12px',
                    backgroundColor: '#6d6c6c',
                    fontSize: '0.9rem',
                    fontWeight: '600',
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontFamily: 'Lato, sans-serif',
                    '&:hover': {
                      backgroundColor: '#6d6c6c',
                    },
                  }}
                >
                  User Manual
                </Button>
              </a>
            ) : (
              <Button
                variant='contained'
                sx={{
                  padding: '5px 12px',
                  backgroundColor: '#ccc',
                  fontSize: '0.9rem',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  color: '#fff',
                  fontFamily: 'Lato, sans-serif',
                  '&:hover': {
                    backgroundColor: '#ccc',
                  },
                }}
                onClick={handleToast}
              >
                User Manual
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductDetails;
